import React, { FC, RefObject, useCallback, useEffect, useState } from 'react'
import styles from './CustomerForm.module.scss'
import { ICustomerFormValue } from './ICustomerFormValue';
import { Button, Icon, IconButton } from '@mui/material';
import { useKeyboard } from '../../../../components/_keyboard/KeyboardContext';
import KeyboardInput from '../../../../components/_keyboard/_KeyboardInput';
import UseTutorialCard from '../../../../modules/onBoarding/presentation/hooks/UseTutorialCard';
export interface ICustomerFormProps {
    //propertys
    onSubmit: (values: ICustomerFormValue) => void
    onClose?: () => void
    title?: string
}
const CustomerForm: FC<ICustomerFormProps> = ({ onSubmit, onClose, title = "Dados do Cliente" }) => {

    const nameInputRef = React.useRef<HTMLInputElement>(null);
    const documentInputRef = React.useRef<HTMLInputElement>(null);
    const phoneNumberInputRef = React.useRef<HTMLInputElement>(null);
    const emailInputRef = React.useRef<HTMLInputElement>(null);
    const submitButtonRef = React.useRef<HTMLButtonElement>(null);
    const [selectedInputRef, setSelectedInputRef] = useState<React.RefObject<HTMLInputElement>>();

    const { openTutorialCard, closeTutorialCard } = UseTutorialCard();

    const divRef = React.useRef<HTMLDivElement>(null);


    useEffect(() => {
        openTutorialCard({
            element: divRef.current,
            options: {
                title: 'Dados do Cliente',
                description: 'Preencha os campos para abrir uma conta',
                buttonLabel: 'Entendi',
                onClose: () => {
                    closeTutorialCard();
                }
            }
        })
    }, [closeTutorialCard, openTutorialCard])

    const { configureKeyboard } = useKeyboard()

    const onSubmitName = useCallback(() => {
        documentInputRef.current?.focus();
    }, [])
    const onSubmitDocument = useCallback(() => {
        phoneNumberInputRef.current?.focus();
    }, [])
    const onSubmitPhone = useCallback(() => {
        emailInputRef.current?.focus();
    }, [])

    const onSubmitHandle = useCallback(() => {
        const values: ICustomerFormValue = {
            name: nameInputRef.current?.value ?? "",
            document: documentInputRef.current?.value ?? "",
            birthDate: undefined,
            phoneNumber: phoneNumberInputRef.current?.value ?? "",
            email: emailInputRef.current?.value ?? "",
            identifier: "",
        }
        onSubmit(values);
    }, [onSubmit])

    const onSubmitEmail = useCallback(() => {
        emailInputRef.current?.blur();
        onSubmitHandle();
    }, [onSubmitHandle])



    const onSubmitKeyboard = useCallback((ref: RefObject<HTMLInputElement>, value: string) => {

        if (ref.current?.id === "name") {
            onSubmitName();
        }
        else if (ref.current?.id === "document") {
            onSubmitDocument();
        }
        else if (ref.current?.id === "phoneNumber") {
            onSubmitPhone();
        }
        else if (ref.current?.id === "email") {
            onSubmitEmail();
        }
    }, [onSubmitDocument, onSubmitEmail, onSubmitName, onSubmitPhone])

    const onChangeFocusKeyboard = useCallback((ev: React.FocusEvent<HTMLInputElement, Element>) => {
        if (ev.target?.id === "name") {
            setSelectedInputRef(nameInputRef);
        }
        else if (ev.target?.id === "document") {
            setSelectedInputRef(documentInputRef);
        }
        else if (ev.target?.id === "phoneNumber") {
            setSelectedInputRef(phoneNumberInputRef);
        }
        else if (ev.target?.id === "email") {
            setSelectedInputRef(emailInputRef);
        }
    }, [])


    useEffect(() => {
        configureKeyboard({
            type: 'qwerty',
            inputRef: selectedInputRef,
            onSubmit: onSubmitKeyboard
        })
    }, [configureKeyboard, onSubmitKeyboard, selectedInputRef])

    useEffect(() => {
        if (!selectedInputRef?.current) {
            setSelectedInputRef(nameInputRef);
        }
    }, [nameInputRef, selectedInputRef])
    return (
        <>
            <div id={styles.CustomerForm}>
                <div className={styles.container} >
                    <IconButton className={styles.closeButton} onClick={onClose}><Icon>close</Icon></IconButton>
                    <h1>{title}</h1>
                    <KeyboardInput
                        id={'name'}
                        inputRef={nameInputRef}
                        label={'Nome:'}
                        onFocus={onChangeFocusKeyboard}
                        onSubmit={onSubmitKeyboard}
                    />
                    <KeyboardInput id={'document'}
                        inputRef={documentInputRef}
                        label={'Documento:'}
                        onFocus={onChangeFocusKeyboard}
                        onSubmit={onSubmitKeyboard}
                    />
                    <KeyboardInput id={'phoneNumber'}
                        inputRef={phoneNumberInputRef}
                        label={'Telefone:'}
                        onFocus={onChangeFocusKeyboard}
                        onSubmit={onSubmitKeyboard}
                    />
                    <KeyboardInput id={'email'}
                        inputRef={emailInputRef}
                        onFocus={onChangeFocusKeyboard}
                        label={'E-mail:'}
                        onSubmit={onSubmitKeyboard}
                    />
                    <Button ref={submitButtonRef} size='large' variant='contained' onClick={onSubmitHandle}> Abrir Conta</Button>
                </div>
            </div>
        </>
    )
}
export default CustomerForm