import React, { FC, useEffect } from 'react'
import styles from './ObservationInput.module.scss'
import KeyboardInput from '../../../../components/_keyboard/_KeyboardInput'
import KeyboardModal from '../../../../components/_keyboard/_KeyboardModal'
import Keyboard from '../../../../components/_keyboard/Keyboard'
import { useKeyboard } from '../../../../components/_keyboard/KeyboardContext'
export interface IObservationInputProps {
    //propertys
    onChange?: (value: string) => void
    onFocusInput?: (inputRef: React.RefObject<HTMLInputElement>) => void
    error?: string
}
const ObservationInput: FC<IObservationInputProps> = ({ onChange, onFocusInput, error }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { configureKeyboard } = useKeyboard()

    const onFocusHandle = React.useCallback(() => {
        configureKeyboard({
            inputRef,
            onChange,
            type: 'qwerty'
        })
        onFocusInput?.(inputRef)
    }, [configureKeyboard, onChange, onFocusInput])
    return (
        <>
            <div id={styles.ObservationInput} >
                <KeyboardInput error={error} multiline inputRef={inputRef} onFocus={onFocusHandle} label={'Observação'} onChange={onChange} />
            </div>
            {/* <KeyboardModal
                inputRef={inputRef}
            >
                <Keyboard
                    inputRef={inputRef}
                    type='qwerty'
                    onChange={onChange} />
            </KeyboardModal> */}
        </>
    )
}
export default ObservationInput