import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import UseComsumeOrder from '../../modules/consume/presentation/UseConsumeOrder';
import UseExtract from '../../modules/consume/presentation/UseExtract'
import UseOrderPad from '../../modules/consume/presentation/UseOrderPad'
import { useUi } from '../../modules/ui/presentation/context/UiContext';
import { v4 } from 'uuid'
import UseLocalPrinter from '../../modules/localPrinter/presentation/UseLocalPrinter';
import { SystemType } from '../../modules/device/domain/enums/SystemType';
import { useCartStore } from '../../modules/cart/infra/store/CartStore';
import { PaymentType } from '../../modules/consume/domain/models/PaymentType';
import { queryParamsJSON } from '../../cross-cutting/queryParamsJSON';
import UseAccount from '../../modules/consume/presentation/UseAccount';
import UsePrePaidTickets from '../../modules/prePaidTickets/presentation/UsePrePaidTickets';
import useOperatorStore from '../../modules/operator/store/OperatorStore';
import UseDeviceStore from '../../modules/device/store/DeviceStore';
import { OperationMode } from '../../modules/device/presentation/context/OperationMode';
import { ICancelOrderItemSelectable } from './components/cancelOrderForm/CancelOrderForm';
import { OperatorMode } from '../../modules/device/interface/Device';
import { useSession } from '../../modules/session/presentation/hooks/UseSession';
import { hideLoading, showLoading } from '../../modules/ui/stores/UiStore';
import OnBoardingService from '../../services/api/onBoarding/OnBoardingService';
import { OnBoardingSteps } from '../../modules/onBoarding/domain/enums/OnBoardingSteps';
import UseOnboardingStore from '../../modules/onBoarding/store/OnBoardingStore';
import UseInvoice from '../../modules/invoice/presentation/hooks/UseInvoice';
import UsePaymentCieloPos from '../../modules/PosCielo/presentation/hook/UsePaymentCieloPos';
import ISimplePos from '../../modules/simplePos/model/ISimplePos';

const UseCatalogPage = (ticket?: boolean) => {

    const [device, operationMode, deviceConfig, isSystemType, disabledSound] = UseDeviceStore(state => [state.device, state.operationMode, state.deviceConfig, state.isSystemType, state.disbledSound]);

    const { getOrderPad, orderPad } = UseOrderPad();
    const { getExtract, extract } = UseExtract();
    const { closeAccount, payAccount, cancelOrderItems } = UseAccount();
    const { consumeOrder } = UseComsumeOrder();
    const { logoutSessionHandler, session } = useSession();
    const [onFinishStep] = UseOnboardingStore(state => [state.onFinishStep]);

    const { consumeTicketOrder } = UsePrePaidTickets();

    const { emitBackgroundInvoice } = UseInvoice();

    const { paymentCieloPos } = UsePaymentCieloPos();

    const [cartItems, clearCart, totals] = useCartStore(state => [state.cartItems, state.clearCart, state.totals]);


    const [searchParams, setSearchParms] = useSearchParams();
    const navigate = useNavigate();

    const { sendOrderToLocalPrint } = UseLocalPrinter()
    const [onLoadingExtract, setOnLoadingExtract] = useState(true)
    const { tag, tableId, number } = useParams();

    const [currentOperator] = useOperatorStore((state) => ([state.currentOperator]));

    const { fullScreenToast, toast } = useUi();

    useEffect(() => {
        if (currentOperator === undefined) {
            if (session) {
                logoutSessionHandler();
            }
            clearCart();
            navigate('/session')
        };
    }, [clearCart, currentOperator, logoutSessionHandler, navigate, session])


    useEffect(() => {
        const isPos = isSystemType(SystemType.pospayOrderPad);
        const isPrepay = isSystemType(SystemType.prepayTicket);
        // return !isPos && !isPrepay;
        if (!isPos && !isPrepay) {
            toast("Nenhuma operaçao detectada, modo de visualização");
        }
    }, [isSystemType, navigate, toast])

    const disabledCart = useMemo(() => {
        const isPos = isSystemType(SystemType.pospayOrderPad);
        const isPrepay = isSystemType(SystemType.prepayTicket);
        return !isPos && !isPrepay;
        // if (!isPos && !isPrepay) {
        //     toast("Nenhuma operaçao detectada");
        //     navigate('/session');
        // }
    }, [isSystemType])

    const getExtractAndOrderPad = useCallback(
        async (tag: string) => {
            if (device) {
                try {
                    setOnLoadingExtract(true)
                    const orderPadResponse = await getOrderPad(tag, device.localId);
                    const extractResponse = await getExtract(orderPadResponse.id, device.localId);
                    console.log('EXTRACT', orderPadResponse, extractResponse)
                } catch (e) {
                    console.error(e)
                } finally {
                    setOnLoadingExtract(false)
                }
            }

        }, [device, getExtract, getOrderPad],
    )

    const getExtractByTable = useCallback(
        async (tableId: string) => {
            if (device) {
                try {
                    setOnLoadingExtract(true)
                    const extractResponse = await getExtract(tableId, device.localId);
                    console.log(extractResponse)
                } catch (e) {
                    console.error(e)
                } finally {
                    setOnLoadingExtract(false)
                }
            }

        }, [device, getExtract],
    )

    const getExtractByTagOrTable = useCallback(() => {
        if (tag) {
            getExtractAndOrderPad(tag);
        }
        if (tableId) {
            getExtractByTable(tableId)
        }

    }, [getExtractAndOrderPad, getExtractByTable, tableId, tag])

    useEffect(() => {
        getExtractByTagOrTable()
    }, [getExtractByTagOrTable])

    const onClickBackHandle = useCallback(
        () => {
            clearCart();
            if (operationMode === OperationMode.ORDER_PAD) {
                navigate('/orderPad');
            } if (operationMode === OperationMode.TABLE) {
                navigate('/tableMap');
            } else if (operationMode === OperationMode.CASHLESS) {
                navigate('/session');
            } else if (operationMode === OperationMode.TICKET) {
                navigate('/session');
            }
        },
        [clearCart, navigate, operationMode],
    )


    const updateTutorialOnboarding = useCallback(async () => {
        const onBoardingService = OnBoardingService();
        await onFinishStep(onBoardingService, {
            localId: device?.localId ?? '',
            step: ticket ? OnBoardingSteps.OnSendOrderTicket : OnBoardingSteps.OnSendOrder,
            description: 'Ao enviar o pedido'
        })
    }, [device?.localId, onFinishStep])


    const finishPaymentRedirect = useCallback(async () => {
        clearCart();
        try {
            showLoading('@finishPaymentRedirect', 'Finalizando');
            await updateTutorialOnboarding();
        } finally {
            hideLoading('@finishPaymentRedirect');
        }
        if (deviceConfig?.terminal.operatorMode === OperatorMode.ONE) {
            onClickBackHandle();
        } else {

            logoutSessionHandler();
        }
    }, [clearCart, deviceConfig?.terminal.operatorMode, logoutSessionHandler, onClickBackHandle, updateTutorialOnboarding])


    const onClickClosePaymentButtonHandle = useCallback(
        () => {
            setSearchParms(prev => queryParamsJSON(prev, { openCart: undefined, openPayAccount: undefined }))
            getExtractByTagOrTable();
        },
        [getExtractByTagOrTable, setSearchParms],
    )


    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                // event.preventDefault();
                // onClickBackHandle();
            }
        };

        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onClickBackHandle]);


    const sendOrderPosPaySharedOrderPadHandler = useCallback(async () => {
        if (!extract) {
            getExtractByTagOrTable();
            toast('Erro ao enviar pedido', 'error');
            return;
        }
        if (tableId && extract?.accountId && extract && currentOperator?.id) {
            await consumeOrder(tableId, extract?.accountId, cartItems, number ?? "", v4(), currentOperator?.id)
            fullScreenToast("Enviado com sucesso", 'success', { sound: !disabledSound, action: finishPaymentRedirect })
            // onClickBackHandle();

            // const orderId: string = v4()
            // const order: IOrder = {
            //     id: orderId,
            //     orderItem: cartItems,
            // }
            // if (deviceConfig?.configSystem.find(item => item.systemType === SystemType.pospayOrderPad)?.enableRemotePrint) {
            //     sendOrderToLocalPrint(order, number ?? "");
            // } //TODO:IMPLEMENTAR REMOTE PRINT
        }
    }, [extract, tableId, currentOperator?.id, getExtractByTagOrTable, toast, consumeOrder, cartItems, number, fullScreenToast, disabledSound, finishPaymentRedirect])

    const sendOrderPosPayIndividualOrderPadHandler = useCallback(
        async () => {
            if (!extract) {
                getExtractByTagOrTable();
                toast('Erro ao enviar pedido', 'error');
                return;
            }
            if (orderPad && extract && currentOperator?.id) {
                await consumeOrder(orderPad?.id, orderPad?.accountId, cartItems, number ?? "", v4(), currentOperator?.id)
                fullScreenToast("Pedido enviado com sucesso", 'success', { sound: !disabledSound, action: onClickBackHandle })


                // const orderId: string = v4();
                // const order: IOrder = {
                //     id: orderId,
                //     orderItem: cartItems,
                // }
                // if (deviceConfig?.configSystem.find(item => item.systemType === SystemType.pospayOrderPad)?.enableRemotePrint) {
                //     sendOrderToLocalPrint(order, number ?? "");
                // }//TODO:IMPLEMENTAR REMOTE PRINT
            }
        },
        [extract, orderPad, currentOperator?.id, getExtractByTagOrTable, toast, consumeOrder, cartItems, number, fullScreenToast, disabledSound, onClickBackHandle],
    )

    const paymentwithCieloPos = useCallback(async (orderId: string, amount: number, simplePos?: ISimplePos | null) => {
        try {
            if (!device?.id)
                throw new Error('Dispositivo não encontrado')
            showLoading('@cieloPos', 'Pagando com POS Cielo');
            const response = await paymentCieloPos(device?.id, orderId, amount, simplePos?.posId);
            return response;
        } catch (e: any) {
            throw new Error(e.message ?? 'Erro ao pagar com CIELO');
        } finally {
            hideLoading('@cieloPos');
        }
    }, [device?.id, paymentCieloPos])

    const sendOrderTicketHandler = useCallback(async (paymentType: PaymentType, simplePos?: ISimplePos) => {
        if (isSystemType(SystemType.prepayTicket)) {
            let paymentTransaction: string | null = null;
            try {
                if (paymentType === PaymentType.cielo) {

                    var cieloResponse = await paymentwithCieloPos(v4(), totals.totalPrice, simplePos);
                    paymentTransaction = JSON.stringify(cieloResponse);

                }
                showLoading('@sendOrderTicketHandler', 'Enviando pedido');
                const response = await consumeTicketOrder(cartItems, totals.totalPrice, paymentType === PaymentType.cielo ? PaymentType.credito : paymentType, undefined, paymentTransaction ?? undefined);
                fullScreenToast('Pedido enviado com sucesso!', 'success', { sound: !disabledSound, action: finishPaymentRedirect });
                if (session?.emitInvoice) { emitBackgroundInvoice(response.order.id, response.customer?.document ?? ''); }
            } catch (error: any) {
                hideLoading('@sendOrderTicketHandler');
                fullScreenToast(error?.message ?? 'Erro ao enviar pedido', 'warning', { sound: !disabledSound, icon: 'error' });
            } finally {
                hideLoading('@sendOrderTicketHandler');
            }
        }
    }, [isSystemType, consumeTicketOrder, cartItems, totals.totalPrice, fullScreenToast, disabledSound, finishPaymentRedirect, session?.emitInvoice, paymentwithCieloPos, emitBackgroundInvoice]);

    const onSubmitPayment = useCallback(async (paymentType: PaymentType, amount: number, payServiceFee?: boolean, simplePos?: ISimplePos | null) => {
        try {
            if (extract) {

                if (paymentType === PaymentType.cielo) {
                    await paymentwithCieloPos(v4(), amount, simplePos);
                }
                showLoading('@onClickPayAccountButtonHandle', 'Pagando conta');


                const response = await payAccount(paymentType === PaymentType.cielo ? PaymentType.credito : paymentType, extract, amount, payServiceFee);
                if (response.accountClosed) {
                    fullScreenToast('Pagamento realizado com sucesso! Conta fechada.', 'success', { sound: !disabledSound, action: finishPaymentRedirect });
                    if (session?.emitInvoice) { emitBackgroundInvoice(response.orderId, extract.user?.document ?? '') }
                    // onClickBackHandle();
                    // finishPaymentRedirect();
                } else {
                    onClickClosePaymentButtonHandle();
                    toast('Pagamento realizado com sucesso!', 'success');
                }
            }
        } catch (error: any) {
            // toast(error?.message ?? "falha ao pagar conta", 'error');
            fullScreenToast(error?.message ?? "falha ao pagar conta", 'warning', { sound: !disabledSound, icon: 'error' });
        } finally {
            hideLoading('@onClickPayAccountButtonHandle');
        }

    }, [extract, payAccount, paymentwithCieloPos, fullScreenToast, disabledSound, finishPaymentRedirect, session?.emitInvoice, emitBackgroundInvoice, onClickClosePaymentButtonHandle, toast])


    const closeAccountHandle = useCallback(async () => {
        try {
            showLoading('@onClickPayAccountButtonHandle', 'Fechando conta');
            await closeAccount(extract);
            fullScreenToast('Conta fechada com sucesso!', 'success', { sound: !disabledSound, action: finishPaymentRedirect });
            // onClickBackHandle();
            // finishPaymentRedirect()

        } catch (error: any) {
            toast(error.message ?? 'Erro ao fechar conta', 'error');
        } finally {
            hideLoading('@onClickPayAccountButtonHandle');
        }
    }, [closeAccount, disabledSound, extract, finishPaymentRedirect, fullScreenToast, toast]);


    const cancelAccoutOrderItemsHandler = useCallback(async (cancelOrderValue: ICancelOrderItemSelectable[], annotation: string) => {
        try {
            showLoading('@cancelOrderItems', 'Cancelando itens');
            await cancelOrderItems(cancelOrderValue, annotation, extract);
            getExtractByTagOrTable();
            return
        } catch (error: any) {
            toast(error.message ?? 'Erro ao cancelar itens', 'error');
            throw new Error(error.message ?? 'Erro ao cancelar itens');
        } finally {
            hideLoading('@cancelOrderItems');
        }
    }, [cancelOrderItems, extract, getExtractByTagOrTable, toast])

    return ({
        extract,
        orderPad,
        tag,
        number,
        onLoadingExtract,
        onClickBackHandle,
        sendOrderPosPaySharedOrderPadHandler,
        sendOrderPosPayIndividualOrderPadHandler,
        sendOrderTicketHandler,
        onSubmitPayment,
        closeAccountHandle,
        getExtractAndOrderPad,
        cancelOrderItems,
        cancelAccoutOrderItemsHandler,
        disabledCart
    })
}



export default UseCatalogPage
