import React, { FC, ReactElement, ReactNode, useCallback } from 'react'
import { toast } from 'react-toastify'
import SendToSocketPrinterUseCase from '../../application/SendToSocketPrinterUseCase'
import MqttService from '../../../../services/webSocketMqtt/MqttService'
import UseDeviceStore from '../../../device/store/DeviceStore'
import html2canvas from 'html2canvas'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
const UseSocketPrinter = () => {
    const { hub } = UseDeviceStore(state => ({ hub: state.hub }))

    const sentToPrinter = useCallback(async (id: string, printerName: string, html?: string, base64?: string) => {
        if (!hub?.key) {
            throw new Error("Nenhum hub conectado")
        }
        const socket = MqttService();
        if (hub?.key) {
            await SendToSocketPrinterUseCase(socket, hub?.key, {
                id: id,
                printerName,
                base64,
                html
            })
        }
    }, [hub?.key])

    //EXEMPLO DE USO
    //const sentInvoiceToPrinter = async () => {
    //    try {
    //        showLoading()
    //        const html = await getElementHtml(<InvoicePrinterLayout invoice={order.invoice[0]} qrCode={qrCode} />)
    //        await sentToPrinter(order.id, "printerName", html)
    //    }catch{
    //        throw new Error("Erro ao imprimir nota fiscal")
    //    } finally {
    //        hideLoading()
    //    }
    //}


    const sendBackgroundPrinter = async (id: string, printerName: string, html?: string, base64?: string) => {
        await toast.promise(sentToPrinter(id, printerName, html, base64),
            {
                pending: { render: 'Enviando para impressora', type: 'info' },
                success: { render: 'Impressão enviada com sucesso', type: 'success', autoClose: 3000 },
                error: { render: ({ data }) => { console.log(data); return data.message }, type: 'error' }
            },
            {
                position: 'top-right'
            }
        )
    }

    const htmlToBase64 = useCallback(async (html: ReactElement) => {
        const element = document.createElement('div');
        ReactDOM.render(html, element);

        document.body.appendChild(element);
        await new Promise(resolve => setTimeout(resolve));

        const canvas = await html2canvas(element, { width: 200, allowTaint: false, useCORS: true });
        document.body.style.overflow = 'hidden';;
        document.body.removeChild(element);

        const base64Image = canvas.toDataURL("image/png");
        console.log('base64Image', base64Image);
        return base64Image;

    }, []);

    const getElementHtml = useCallback(async (html: ReactElement) => {
        await new Promise(resolve => setTimeout(resolve));
        const htmlString = ReactDOMServer.renderToStaticMarkup(html);
        // const element = document.createElement('div');
        // element.innerHTML = htmlString;
        // document.body.appendChild(element);
        return htmlString;
    }, [])




    return ({ sendBackgroundPrinter, htmlToBase64, getElementHtml })
}

export default UseSocketPrinter




