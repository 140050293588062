import { ArrowRight, CancelOutlined, Close, CloseOutlined, KeyboardArrowRight } from '@mui/icons-material';
import { Button, Drawer, IconButton, Skeleton } from '@mui/material';
import React, { FC, useState } from 'react'
import { IExtract } from '../../../../modules/consume/domain/models/Extract'
import { IOrderPad } from '../../../../modules/consume/domain/models/OrderPad';
import ExtractOrders from './components/ExtractOrders';
import styles from './ExtractResume.module.scss'
export interface IExtractResumeProps {
    //propertys
    extract?: IExtract;
    orderPad?: IOrderPad;
    tag?: string,
    number?: string,
    onLoading?: boolean
    onClickReadTag?: () => void
    onClickCancelItem?: () => void
}
const ExtractResume: FC<IExtractResumeProps> = ({ extract, tag, number, onClickReadTag, orderPad, onLoading = true, onClickCancelItem }) => {

    const [extractModalOpen, setExtractModalOpen] = useState(false)
    return (
        onLoading ? <div id={styles.ExtracLoading}>
            <Skeleton width={200} height={60} />
            <Skeleton width={100} height={60} />
            <div style={{ display: 'flex', gap: 8 }}>
                <Skeleton style={{ display: 'flex', flex: 1 }} height={60} />
                <Skeleton style={{ display: 'flex', flex: 1 }} height={60} />
                <Skeleton style={{ display: 'flex', flex: 1 }} height={60} />
                <Skeleton style={{ display: 'flex', flex: 1 }} height={60} />
            </div>
            <Skeleton width={120} height={40} />

        </div> :
            !extract ? <div id={styles.ExtracNotFound}><span>
                Extrato não encontrado
            </span>
                <div className={styles.readOrderPadTagButton} onClick={onClickReadTag} >Ler comanda novamente</div>
            </div> :
                <div id={styles.ExtractResume} >
                    {/* <div className={styles.imageContainer} >
                    <div className={styles.imageContainer} ></div>
                </div> */}
                    <div className={styles.container} >
                        <div className={styles.user} >
                            <div className={styles.user} >
                                <div className={styles.name} >
                                    {extract.user?.name ?? "Nome não informado"}
                                </div>
                            </div>
                            <div className={styles.orderPad} >
                                {tag && <span >
                                    Comanda: <b>{tag}</b>
                                </span>}
                                {number && <span>
                                    Mesa:<b> {number}</b>
                                </span>}
                            </div>
                            <div>
                                {orderPad?.openedAt}
                            </div>
                        </div>

                        <div className={styles.data} >
                            <div className={styles.item}>
                                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract.totalAmount)}</span>
                                <label>Total Consumido</label>
                            </div>
                            <div className={styles.item}>
                                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract.totalToPayWithFee - extract.totalToPayWithoutFee)}</span>
                                <label>Taxa de serviço</label>
                            </div>
                            <div className={styles.item}>
                                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract.totalToPayWithFee)}</span>
                                <label>Total a pagar</label>
                            </div>
                            {!!extract.totalDiscount && <div className={styles.item}>
                                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract.totalDiscount)}</span>
                                <label>Desconto</label>
                            </div>}
                            {/* <div className={styles.item}>
                            <span>{extract.paidAmount}</span>
                            <label>Total a pagar</label>
                        </div> */}
                            {!!extract.minimumConsumption && <div className={styles.item}>
                                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract.minimumConsumption)}</span>
                                <label>Minimo</label>
                            </div>}
                            {/* <div className={styles.item}>
                            <span>{extract.totalToPayWithoutFee}</span>
                            <label>Total</label>
                        </div> */}
                        </div>
                        <Button variant='text' onClick={() => setExtractModalOpen(true)} ><u>Visualizar Extrato</u> <KeyboardArrowRight /> </Button>
                        <Button variant='text' onClick={onClickCancelItem} ><u>Cancelar itens </u><CancelOutlined /></Button>
                    </div>
                    <Drawer anchor='right' open={extractModalOpen} onClose={() => setExtractModalOpen(false)}>
                        <div className={styles.extractOrderDrawer}>
                            <div className={styles.headerTitle}>
                                <h2>Extrato</h2>
                                <IconButton onClick={() => setExtractModalOpen(false)}>
                                    <Close />
                                </IconButton>
                            </div>
                            <ExtractOrders extract={extract}></ExtractOrders>
                        </div>
                    </Drawer>
                </div >
    )
}
export default ExtractResume