import React, { FC, useCallback } from 'react';
import LocalPrinterApi from '../../../services/api/localPrinter/LocalPrinterApi';
import { IOrder } from '../../cart/domain/models/IOrder';
import { useUi } from '../../ui/presentation/context/UiContext';
import SendToLocalPrinterUseCase from '../application/SendToLocalPrinterUseCase';
import UseDeviceStore from '../../device/store/DeviceStore';
import { hideLoading, showLoading } from '../../ui/stores/UiStore';
const UseLocalPrinter = () => {
  const {  toast } = useUi();
  const [device, deviceConfig] = UseDeviceStore((state) => [
    state.device,
    state.deviceConfig,
  ]);

  const sendOrderToLocalPrint = useCallback(
    (order: IOrder, tagOrNumber: string) => {
      try {
        if (device) {
          showLoading('@localPrinterSend', 'Pedido sendo enviado para cozinha');
          const serviceLocalPrinter = LocalPrinterApi();
          if (deviceConfig?.printer?.remotePrinters?.length) {

            SendToLocalPrinterUseCase(
              serviceLocalPrinter,
              tagOrNumber,
              device?.deviceName,
              order,
              deviceConfig.printer?.remotePrinters[0].ipAddress, //TODO: rever configuracao de impressora com profile
            );
          }
        } else {
          toast('Device não registrado');
        }
      } catch {
        toast('Falha ao enviar para alguma das impressoras informe um atendente', 'error');
      } finally {
        hideLoading('@localPrinterSend');
      }
    },
    [device, deviceConfig?.printer?.remotePrinters, toast],
  );

  return { sendOrderToLocalPrint };
};

export default UseLocalPrinter;
