import React, { FC, RefObject, useCallback, useEffect, useMemo } from 'react'
import styles from './PayAccountForm.module.scss'
import { IExtract } from '../../../../modules/consume/domain/models/Extract';
import { PaymentType } from '../../../../modules/consume/domain/models/PaymentType';
import { Button, Drawer, Icon, IconButton } from '@mui/material';
import { scrooToElement } from '../../../../cross-cutting/ScrollToElement';
import { PaymentSelector } from '../../../catalog/components/paymentTypeSelector/PaymentSelector';
import { PaymnetExtractResume } from './paymentExtractResume/PaymnetExtractResume';
import PartialPaymentOption from '../options/PartialPaymentOption';
import ServiceFeeOption from '../options/ServiceFeeOptionProps';
import ISimplePos from '../../../../modules/simplePos/model/ISimplePos';
import { SimplePosSelector } from '../../../catalog/components/SimplePosSelector/SimplePosSelector';
export interface ICloseAccountFormProps {
    //propertys
    onClose?: () => void;
    extract: IExtract;
    onSubmit: (paymentType: PaymentType, amount: number, payServiceFee?: boolean, simplePos?: ISimplePos | null) => void;
    simplePosList?: ISimplePos[];
}
const PayAccountForm: FC<ICloseAccountFormProps> = ({ extract, onClose, onSubmit, simplePosList }) => {
    const [simplePos, setSimplePos] = React.useState<ISimplePos | null>(null);
    const [payServiceFee, setPayServiceFee] = React.useState(true);
    const [paymentType, setPaymentType] = React.useState<PaymentType>();
    const [partialAmount, setPartialAmount] = React.useState<number>(payServiceFee ? extract.totalToPayWithFee : extract.totalToPayWithoutFee);
    const onClickPaymentButtonHandle = useCallback(() => {
        if (!paymentType) {
            return
        }
        if (partialAmount <= 0) {
            return
        }

        onSubmit(paymentType, partialAmount, payServiceFee, simplePos)

    }, [onSubmit, partialAmount, payServiceFee, paymentType, simplePos]);

    const onCheckPayServiceFeeHandle = useCallback((checked: boolean) => {
        setPartialAmount(checked ? extract.totalToPayWithFee : extract.totalToPayWithoutFee)
        setPayServiceFee(checked);
    }, [extract.totalToPayWithFee, extract.totalToPayWithoutFee])


    const validate = useMemo(() => {
        if (!paymentType) {
            return false
        }
        if (partialAmount <= 0) {
            return false
        }
        return true
    }, [paymentType, partialAmount]);


    const totalToPay = useMemo(() => payServiceFee ? extract.totalToPayWithFee : extract.totalToPayWithoutFee, [extract.totalToPayWithFee, extract.totalToPayWithoutFee, payServiceFee])




    const onFocusInput = useCallback((inputEvent: RefObject<HTMLInputElement>) => {
        scrooToElement(inputEvent);
    }, []);

    return (
        <div id={styles.PayAccountForm} >
            <div className={styles.header} >
                <IconButton onClick={onClose} className={styles.closeButton}><Icon >close</Icon></IconButton>
                <h2>Pagamento</h2>
            </div>
            <div className={styles.container} >
                <div className={styles.content} >
                    <ServiceFeeOption
                        extract={extract}
                        payServiceFee={payServiceFee}
                        onCheckPayServiceFeeHandle={onCheckPayServiceFeeHandle}
                    />
                    <PartialPaymentOption
                        onFocusInput={onFocusInput}
                        amountToPay={totalToPay}
                        extract={extract}
                        total={extract.totalAmount}
                        onChange={setPartialAmount}
                    />

                    <PaymnetExtractResume
                        extract={extract}
                        payServiceFee={payServiceFee}
                        totalToPay={totalToPay}
                        partialAmount={partialAmount}
                    />

                    <PaymentSelector
                        paymentType={paymentType}
                        onChangePaymentType={setPaymentType}
                        onFocusInput={onFocusInput}
                        amountToPay={partialAmount}
                        // showCielo={simplePosList && simplePosList.length > 0}
                        showCielo
                    />
                    {
                        paymentType === PaymentType.cielo &&
                        <SimplePosSelector
                            onChangeSimplePos={setSimplePos}
                            selectedSimplePos={simplePos}
                            simplePosList={simplePosList}
                        />
                    }
                </div>
                <div className={styles.footer}>
                    <Button variant={'contained'} disabled={!validate} size='large' color='primary' className={styles.button} onClick={onClickPaymentButtonHandle}>
                        Enviar pagamento
                    </Button>
                </div>
            </div>

        </div >
    )
}
export default PayAccountForm


