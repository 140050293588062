import React from 'react';
import styles from './CurrentSessionStatus.module.scss';
import { Button, Icon } from '@mui/material';
import { ISession } from '../../../../modules/session/domain/ISession';
import { IDevice } from '../../../../modules/device/interface/Device';
import { OperatorPermissions } from '../../../../modules/operator/domains/models/Permissions';

interface ICurrentSessionStatusProps {
    device?: IDevice;
    currentSession?: ISession;
    onSubmitRemoveSession: () => void;
    onSubmitCloseSessionPassword: () => void;
    onClickGoToCatalog: () => void;
    hasPermission: (Permissions: OperatorPermissions) => boolean;
}
export const CurrentSessionStatus: React.FC<ICurrentSessionStatusProps> = ({ onSubmitRemoveSession, onSubmitCloseSessionPassword, device, currentSession, onClickGoToCatalog, hasPermission }) => {

    return <div id={styles.CurrentSessionStatus}>
        {/* <KeyboardInput inputRef={operatorInputRef} password error={error} label={'Senha do operador'} /> */}
        <h2>Caixa aberto</h2>
        {device && <p>Local: {device.localName}</p>}
        {device && <p>Dispositivo: {device?.deviceName}</p>}
        {currentSession?.openedAt && <p>Aberto em: {currentSession?.openedAt.toLocaleString()}</p>}
        <Button onClick={onClickGoToCatalog} fullWidth variant={'contained'} size='large'><Icon>restaurant</Icon>Ir para catálogo</Button>
        <div className={styles.buttonContainer}>
            {/* {hasPermission(OperatorPermissions.LOGOUT_SESSION) && */}
            <Button onClick={onSubmitRemoveSession} fullWidth variant={'outlined'} size='large'>
                {/* <Icon fontSize='small'>logout</Icon> */}
                Sair
            </Button>
            {/*} */}
            {hasPermission(OperatorPermissions.CLOSE_SESSION) &&
                <Button onClick={onSubmitCloseSessionPassword} fullWidth variant={'outlined'} size='large'>
                    {/* <Icon fontSize='small'>logout</Icon> */}
                    Fechar
                </Button>}
        </div>
    </div>;
};
