import React, { FC, RefObject, useCallback, useEffect, useMemo } from 'react'
import styles from './_PayTicketOrderForm.module.scss'
import { PaymentType } from '../../../../modules/consume/domain/models/PaymentType';
import { Button, Icon, IconButton } from '@mui/material';
import { scrooToElement } from '../../../../cross-cutting/ScrollToElement';
import { PaymentSelector } from '../paymentTypeSelector/PaymentSelector';
import { IOrder } from '../../../../modules/cart/domain/models/IOrder';
import { PaymentOrderResume } from './ticketOrderResume/PaymentOrderResume';
import { IOrderItem } from '../../../../modules/cart/domain/models/IOrderItem';
import ISimplePos from '../../../../modules/simplePos/model/ISimplePos';
import { SimplePosSelector } from '../SimplePosSelector/SimplePosSelector';
export interface ICloseAccountFormProps {
    //propertys
    onClose?: () => void;
    orderItems: IOrderItem[];
    amountToPay: number;
    onSubmit: (paymentType: PaymentType, amount?: number, simplePos?: ISimplePos | null) => void;
    simplePosList?: ISimplePos[];
}
const PayTicketForm: FC<ICloseAccountFormProps> = ({ onClose, onSubmit, amountToPay, orderItems, simplePosList }) => {

    const [simplePos, setSimplePos] = React.useState<ISimplePos | null>(null);

    const [paymentType, setPaymentType] = React.useState<PaymentType>();

    const onClickPaymentButtonHandle = useCallback(() => {
        if (!paymentType) {
            return
        }
        if (paymentType === PaymentType.cielo && simplePosList?.length && simplePos === undefined) {
            return
        }
        onSubmit(paymentType, amountToPay, paymentType === PaymentType.cielo ? simplePos : undefined);

    }, [paymentType, simplePosList?.length, simplePos, onSubmit, amountToPay]);

    const validate = useMemo(() => {
        if (!paymentType) {
            return false
        }
        if (orderItems && orderItems.length === 0) {
            return false
        }
        if (paymentType === PaymentType.cielo) {
            // if (!simplePosList?.length) {
            //     return false;
            // }
            if (simplePos === undefined) {
                return false;
            }
        }
        return true
    }, [paymentType, orderItems, simplePos]);

    useEffect(() => {
        if (simplePosList && simplePosList.length) {
            setSimplePos(simplePosList[0]);
        }
    }, [simplePosList])


    const onFocusInput = useCallback((inputEvent: RefObject<HTMLInputElement>) => {
        scrooToElement(inputEvent);
    }, [])

    return (
        <div id={styles.PayTicketForm} >
            <div className={styles.header} >
                <IconButton onClick={onClose} className={styles.closeButton}><Icon >close</Icon></IconButton>
                <h2>Pagamento</h2>
            </div>
            <div className={styles.container} >
                <div className={styles.content} >
                    <PaymentOrderResume
                        orderItems={orderItems}
                        totalToPay={amountToPay}
                    />
                    <PaymentSelector
                        paymentType={paymentType}
                        onChangePaymentType={setPaymentType}
                        onFocusInput={onFocusInput}
                        amountToPay={amountToPay}
                        // showCielo={simplePosList && simplePosList.length > 0}
                        showCielo
                    />
                    {
                        paymentType === PaymentType.cielo && simplePosList && simplePosList.length > 0 &&
                        <SimplePosSelector
                            onChangeSimplePos={setSimplePos}
                            selectedSimplePos={simplePos}
                            simplePosList={simplePosList}
                        />
                    }
                </div>
                <div className={styles.footer}>
                    <Button
                        variant={'contained'}
                        disabled={!validate}
                        size='large'
                        color='primary'
                        className={styles.button}
                        onClick={onClickPaymentButtonHandle}>
                        Enviar pagamento
                    </Button>
                </div>
            </div>
        </div >
    )
}
export default PayTicketForm


