
import { Button, Icon, IconButton } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { IOrderComposition } from '../../../../modules/cart/domain/models/IOrderComposition';
import { useCatalog } from '../../../../modules/catalog/context/CatalogContext';
import { IProduct } from '../../../../modules/catalog/domain/models/IProduct';
import ProductCompositions from '../productComposition/ProductCompositions';
import styles from './ProductItem.module.scss'
import UseProductItem from './UseProductItem'
import Image from '../../../../components/ui/image/Image';
import ObservationInput from '../observationInput/ObservationInput';
import useOnScreen from '../../../../components/onScreen/UseOnScreen';
import { ProductType } from '../../../../modules/catalog/domain/models/ProductType';
import WeightInput from '../weightInput/WeightInput';

export interface IProductProps {
    //propertys
    product: IProduct,
    onClickAddProduct: (product: IProduct, quantity: number, compositions?: IOrderComposition[], notes?: string) => void
    hideImage?: boolean
    onClickEditObservation?: (inputRef: React.RefObject<HTMLInputElement | HTMLDivElement>, block?: 'start' | 'center') => void
    disabledCart?: boolean
}
const ProductItem: FC<IProductProps> = ({ product, onClickAddProduct, hideImage, onClickEditObservation, disabledCart }) => {
    const ref = useRef<HTMLDivElement>(null);
    const {
        compositions,
        quantity,
        onClickAddQuantityHandle,
        onClickRemoveQuantityHandle,
        onClickAddProductHandle,
        onChangeCompositionHandle,
        handleChangeObservation,
        onClickProduct,
        openProduct,
        disabledAddProduct,
        setOpenProduct,
        setWheight,
        wheight
    } = UseProductItem(product, onClickAddProduct, ref, onClickEditObservation);

    const { catalog } = useCatalog();
    const isVisible = useOnScreen(ref);

    const showProduct = useMemo(() => (isVisible || openProduct), [isVisible, openProduct])

    const observation = useMemo(() => <div className={styles.customObservation}>
        {
            // !!product.productGeneralObservation && 
            !disabledCart &&
            <ObservationInput onChange={handleChangeObservation} onFocusInput={onClickEditObservation} />
        }
    </div>, [disabledCart, handleChangeObservation, onClickEditObservation])

    const weight = useMemo(() => <div>

        {
            product.type === ProductType.Kilo && <div className={styles.customObservation}>
                <WeightInput onChange={setWheight} onFocusInput={onClickEditObservation} onSubmit={onClickAddProductHandle} />
                {/* <div className={styles.price} >
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(product?.price * wheight)}
                </div> */}
            </div>
        }
    </div>, [onClickAddProductHandle, onClickEditObservation, product.type, setWheight])

    return (
        <>
            <div ref={ref} id={styles.ProductItem} style={{ border: openProduct ? "1px solid var(--primary-color)" : 'unset' }}>
                {
                    !showProduct ?
                        <div className={styles.container} >
                            <div style={{ width: 90, height: 90 }}>
                                <div style={{ width: 90, height: 90 }}>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={styles.container} >
                            <div className={openProduct ? styles.headerSelected : styles.header} style={{ visibility: !showProduct ? 'hidden' : 'unset' }} >
                                <div className={styles.clickable} onClick={!openProduct && !disabledCart ? onClickProduct : undefined} />
                                {!hideImage &&
                                    <div style={{ width: 90, height: 90 }}>
                                        <Image className={styles.img} width={90} height={90} src={product.thumbnailUrl}></Image>
                                    </div>
                                }
                                <div className={styles.info} >
                                    <div className={styles.name} >
                                        {product?.name}
                                    </div>
                                    <div className={styles.price} >
                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(product?.price)}
                                        {
                                            product.type === ProductType.Kilo && ` / kg`
                                        }
                                    </div>
                                </div>
                                {/* <div>
                                    {product.barCode}
                                </div> */}
                                {
                                    // !product.compositions?.length ? <Icon color='primary'>add</Icon> :
                                    !openProduct ?
                                        <IconButton className={styles.openButton} onClick={() => setOpenProduct(true)}><Icon color='primary'>expand_more</Icon></IconButton>
                                        :
                                        <IconButton className={styles.openButton} onClick={() => setOpenProduct(false)}><Icon color='primary'>expand_less</Icon></IconButton>
                                }
                            </div>

                            {openProduct &&
                                <div className={styles.detail}>

                                    <div className={styles.description} >
                                        {product?.description}
                                    </div>
                                    {product.compositions && !catalog?.hideCompositions &&
                                        <div className={styles.compositions}>
                                            <ProductCompositions
                                                disabled={disabledCart}
                                                productCompositions={product.compositions}
                                                onChangeOrderCompositions={onChangeCompositionHandle}
                                            />
                                        </div>
                                    }
                                    {weight}

                                    {product.type === ProductType.Produto && observation}
                                    {
                                        (catalog?.enableShoppingCart !== false && !disabledCart) &&
                                        (
                                            product.type === ProductType.Produto ?
                                                (
                                                    <div className={styles.footer}>

                                                        <div className={styles.quantity}>
                                                            <IconButton
                                                                disabled={!(quantity > 1)}
                                                                className={!(quantity > 1) ? styles.disabledIcon : styles.buttonIcons}
                                                                onClick={onClickRemoveQuantityHandle}><Icon>remove</Icon></IconButton>
                                                            {quantity}
                                                            <IconButton
                                                                className={styles.buttonIcons}
                                                                onClick={onClickAddQuantityHandle}
                                                            ><Icon>add</Icon></IconButton>
                                                        </div>
                                                        <div className={styles.buttonContainer}>
                                                            <Button disabled={disabledAddProduct} fullWidth variant="contained" onClick={onClickAddProductHandle} color="primary">
                                                                Adicionar {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(quantity * (product.price + (compositions?.totals.price ?? 0)))}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div className={styles.buttonContainer}>
                                                        <Button disabled={disabledAddProduct || wheight <= 0} fullWidth variant="contained" onClick={onClickAddProductHandle} color="primary">
                                                            Adicionar {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(wheight * (product.price + (compositions?.totals.price ?? 0)))}
                                                        </Button>
                                                    </div>
                                                )
                                        )

                                    }
                                </div>}
                        </div>
                }
            </div>
        </>
    )
}
export default ProductItem
