import { ConsumeOrderRequest } from "../../../modules/consume/domain/dtos/ConsumeOrderRequest";
import { SyncOrderRequest } from "../../../modules/prePaidTickets/domain/dtos/SyncPrePaidTickets";
import { IPrePaidTicketsService } from "../../../modules/prePaidTickets/domain/interfaces/IPrePaidTicketsService";
import { Api } from "../bases/Api";

const PrePaidTicketsApi = (): IPrePaidTicketsService => {
    const api = Api();

    const syncOrder = async (request: SyncOrderRequest[]): Promise<void> => {
        const response = await api.post<SyncOrderRequest[]>(`/PrePaidTickets/SyncTickets`, request);
    }
    
    return ({ syncOrder })
}
export default PrePaidTicketsApi