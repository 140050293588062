
import SessionApi from '../../../../services/api/session/SessionApi';
import { useUi } from '../../../ui/presentation/context/UiContext';
import { useNavigate } from 'react-router-dom';
import { IOperator } from '../../../operator/domains/models/Operator';
import UseDeviceStore from '../../../device/store/DeviceStore';
import UseSessionStore from '../../store/SessionStore';
import useOperatorStore from '../../../operator/store/OperatorStore';
import { useShallow } from 'zustand/react/shallow'
import { useState, useCallback, useEffect } from 'react';
import useAuthStore from '../../../authentication/stores/AuthStore';
import { hideLoading, showLoading } from '../../../ui/stores/UiStore';

export const useSession = () => {

    const session = UseSessionStore(useShallow(state => state.session));
    const [verifyOpenedSession, openSession, closeSession, loginSession, logoutSession, setSession, hasSession] = UseSessionStore(state => [state.verifyOpenedSession, state.openSession, state.closeSession, state.loginSession, state.logoutSession, state.setSession, state.hasSession]);

    const [device] = UseDeviceStore(state => [state.device, state.setDeviceConfig]);
    const logoutOperator = useOperatorStore(state => state.logoutOperator);
    const currentOperator = useOperatorStore(useShallow(state => state.currentOperator));

    const [isLoading, setIsLoading] = useState(false);
    const {  toast } = useUi();
    const navigate = useNavigate();

    const [auth] = useAuthStore(state => [state.auth]);

    const logoutSessionHandler = useCallback(async () => {
        if (!session) { toast("Sessão nao encontrada"); return; }
        if (!device) { toast("Dispositivo não encontrado"); return; }
        try {
            showLoading('@logoutSession', 'Fechando sessão');
            const service = SessionApi();
            if (currentOperator) {
                await logoutSession(service, device.localId, currentOperator.id);
                await logoutOperator();
                navigate('/session');
            } else {
                logoutOperator();
                setSession(undefined);
                verifyOpenedSession(service, device.localId);
            }
        } finally {
            hideLoading('@logoutSession');
        }
    }, [currentOperator, device, logoutOperator, logoutSession, navigate, session, setSession, toast, verifyOpenedSession]);

    const getSessionHandler = useCallback(async (operator: IOperator) => {
        if (device) {
            try {
                const service = SessionApi();
                showLoading('@getSession', 'Carregando sessão');
                const response = await loginSession(service, device.localId, operator.id);
                return response;
            } catch (error) {
                logoutOperator();
            } finally {
                const service = SessionApi();
                verifyOpenedSession(service, device?.localId);
                hideLoading('@getSession');
            }
        }
    }, [device, loginSession, logoutOperator, verifyOpenedSession])

    const closeSessionHandler = useCallback(async (sessionId: string, operator: IOperator) => {
        if (device) {
            const service = SessionApi();
            try {
                const service = SessionApi();
                showLoading('@closeSession', 'Fechando sessão');
                await closeSession(service, device.localId, operator.id);
                logoutOperator();
            } finally {
                verifyOpenedSession(service, device.localId);
                hideLoading('@closeSession');
            }
        } else {
            throw new Error('Device não encontrado');
        }
    }, [device, closeSession, logoutOperator, verifyOpenedSession]);

    const openSessionHandler = useCallback(async (operator: IOperator) => {
        if (device) {
            try {
                const service = SessionApi();
                showLoading('@openSession', 'Abrindo sessão');
                const response = await openSession(service, device.localId, operator.id);
                return response;
            } catch (error) {
                toast('Erro ao abrir sessão', 'error');
            } finally {
                hideLoading('@openSession');
            }
        } else {
            throw new Error('Device não encontrado');
        }
    }, [device, openSession, toast]);


    useEffect(() => {
        if (auth) {
            if (device?.localId) {
                const service = SessionApi();
                verifyOpenedSession(service, device?.localId);
            }
        }
    }, [auth, device, verifyOpenedSession]);


    return (
        {
            session,
            getSessionHandler,
            closeSessionHandler,
            hasSession,
            openSessionHandler,
            isLoading,
            logoutSessionHandler,
        }
    );
};