import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UseDeviceNavigatorStore from '../../../modules/device/store/DeviceNavigatorStore';
import { UseVerifyNavigator } from '../../../modules/device/presentation/hooks/UseVerifyNavigator';
import useOperatorStore from '../../../modules/operator/store/OperatorStore';
import { OperatorService } from '../../../services/api/operator/OperatorService';
import UseSessionStore from '../../../modules/session/store/SessionStore';
import SessionApi from '../../../services/api/session/SessionApi';
import UseDeviceStore from '../../../modules/device/store/DeviceStore';
import { OperationMode } from '../../../modules/device/presentation/context/OperationMode';
import { hideLoading, showLoading } from '../../../modules/ui/stores/UiStore';
import { useShallow } from 'zustand/react/shallow';
import { OpenSessionAlertRef } from './OpenSessionAlert';
import GetSessionStatusUseCase from '../../../modules/session/application/GetSessionStatusUseCase';


export const usePortalComunication = () => {
    const { approveDeviceHandler } = UseVerifyNavigator();
    const [loginSession] = UseSessionStore(useShallow(state => [state.loginSession]));
    const [terminalId, localId] = UseDeviceNavigatorStore(useShallow(state => [state.terminalId, state.localId]));
    const [operationMode] = UseDeviceStore(useShallow(state => [state.operationMode]));
    const [loginOperatorById] = useOperatorStore(useShallow(state => [state.loginOperatorById]));
    const navigator = useNavigate();

    const goToOperation = useCallback(() => {
        if (operationMode === OperationMode.TICKET) {
            navigator('/catalog/ticket');
            return
        } else {
            navigator('/tableMap');
        }
    }, [navigator, operationMode])

    const goToSession = useCallback(() => {
        navigator('/session');
    }, [navigator])


    const loginOperator = useCallback(async (operatorId: string) => {
        if (operatorId && localId) {
            showLoading('@approveDevice', 'Verficando operador');

            const operatorService = OperatorService();
            const response = await loginOperatorById(operatorService, localId, operatorId);
            hideLoading('@approveDevice');
            try {
                const sessionService = SessionApi();
                const hasOpenSession = await GetSessionStatusUseCase(sessionService, localId);
                if (!hasOpenSession) {
                    await OpenSessionAlertRef.current?.open();
                }
                showLoading('@approveDevice', 'Acessando caixa');
                await loginSession(sessionService, localId, operatorId);
                goToOperation()
            } catch{
                goToSession();
            }
        }
    }, [goToOperation, goToSession, localId, loginOperatorById, loginSession])

    useEffect(() => {
        const event = async (event: MessageEvent) => {
            // if (event.origin === PORTAL_URL) {
            try {
                const data = JSON.parse(event.data)
                if (data.action === 'approve') {
                    if (terminalId) {
                        await loginOperator(data.operatorId);
                        return;
                    }
                    showLoading('@approveDevice', 'Aprovando dispositvo');
                    const response = await approveDeviceHandler();
                    if (response?.terminalId) {
                        await loginOperator(data.operatorId);
                    }
                }
            } catch (e) {
                // console.log('error');
            } finally {
                hideLoading('@approveDevice');
            }
        };
        window.addEventListener('message', event);
        return () => {
            window.removeEventListener('message', event);
        };
    }, [approveDeviceHandler, loginOperator, terminalId]);

};
