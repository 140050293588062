import React, { FC, RefObject, useCallback, useState } from 'react';
import styles from './PaymentSelector.module.scss';
import { PaymentType } from '../../../../modules/consume/domain/models/PaymentType';
import { Button } from '@mui/material';
import { moneyFormater } from '../../../../services/utils/Money';
import MoneyInput from '../../../TableMap/components/partialPaymentInput/MoneyInput';

export interface IPaymentSelectiorProps {
    paymentType: PaymentType | undefined,
    onChangePaymentType: (paymentType: PaymentType) => void,
    onFocusInput: (inputEvent: RefObject<HTMLInputElement>) => void,
    amountToPay: number
    showCielo?: boolean
}

export const PaymentSelector: FC<IPaymentSelectiorProps> = ({
    paymentType, amountToPay, onChangePaymentType, onFocusInput, showCielo
}) => {

    const [moneyValue, setMoneyValue] = useState<number>();

    const onClickPaymentCreditCardButtonHandle = useCallback(() => {
        onChangePaymentType(PaymentType.credito);
    }, [onChangePaymentType]);
    const onClickPaymentDebitCardButtonHandle = useCallback(() => {
        onChangePaymentType(PaymentType.debito);
    }, [onChangePaymentType]);
    const onClickPaymentMoneyButtonHandle = useCallback(() => {
        onChangePaymentType(PaymentType.dinheiro);
    }, [onChangePaymentType]);
    const onClickPaymentPixButtonHandle = useCallback(() => {
        onChangePaymentType(PaymentType.pix);
    }, [onChangePaymentType]);
    const onClickPaymentCieloButtonHandle = useCallback(() => {
        onChangePaymentType(PaymentType.cielo);
    }, [onChangePaymentType]);



    return <div className={styles.paymentType}>
        <b>Forma de pagamento</b>
        <div className={styles.buttonContainer}>

            <Button size='large' classes={{ outlined: styles.outlined, text: styles.text }} variant={paymentType === PaymentType.dinheiro ? "outlined" : "text"} color='primary' className={styles.button} onClick={onClickPaymentMoneyButtonHandle}>
                <img alt={'icone Dinheiro'} src='/assets/img/money.svg' />
                Dinheiro
            </Button>

            <Button size='large' classes={{ outlined: styles.outlined, text: styles.text }} variant={paymentType === PaymentType.credito ? "outlined" : "text"} color='primary' className={styles.button} onClick={onClickPaymentCreditCardButtonHandle}>
                <img alt={'icone  Cartão Crédito'} src='/assets/img/credit-card.svg' />
                Crédito
            </Button>

            <Button size='large' classes={{ outlined: styles.outlined, text: styles.text }} variant={paymentType === PaymentType.debito ? "outlined" : "text"} color='primary' className={styles.button} onClick={onClickPaymentDebitCardButtonHandle}>
                <img alt={'icone Cartão Débito'} src='/assets/img/debit-card.svg' />
                Débito
            </Button>

            <Button size='large' classes={{ outlined: styles.outlined, text: styles.text }} variant={paymentType === PaymentType.pix ? "outlined" : "text"} color='primary' className={styles.button} onClick={onClickPaymentPixButtonHandle}>
                <img alt={'icone  Pix'} src='/assets/img/pix.svg' />
                Pix
            </Button>

            {showCielo && <Button size='large' classes={{ outlined: styles.outlined, text: styles.text }} variant={paymentType === PaymentType.cielo ? "outlined" : "text"} color='primary' className={styles.button} onClick={onClickPaymentCieloButtonHandle}>
                <img width={80} alt={'Cielo'} src='/assets/img/logo-cielo.png' />
                {/* Cielo */}
            </Button>}
        </div>
        {paymentType === PaymentType.dinheiro &&
            <div className={styles.change}>
                <h2>
                    Calcular Troco:
                </h2>
                <MoneyInput sugestion onFocus={onFocusInput} defaultValue={amountToPay} label='Valor em dinheiro' onChange={setMoneyValue} />
                {!!moneyValue && (moneyValue - amountToPay) > 0 && <div className={styles.result}>
                    <label>Troco:</label> <b>{moneyFormater(moneyValue - amountToPay)}</b>
                </div>}
            </div>}
    </div>;
};
