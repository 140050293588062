import { AxiosResponse } from 'axios';
import { ConsumeOrderRequest } from '../../../modules/consume/domain/dtos/ConsumeOrderRequest';
import { GetExtractResponse } from '../../../modules/consume/domain/dtos/GetExtractResponse';
import { IGetOrderPadResponse } from '../../../modules/consume/domain/dtos/GetOrderPadResponse';
import { GetSharedOrderPadsResponse } from '../../../modules/consume/domain/dtos/GetSharedOrderPadResponse';
import { GetTableItemResponse } from '../../../modules/consume/domain/dtos/GetTablesResponse';
import { OpenAccountRequest } from '../../../modules/consume/domain/dtos/OpenAccountRequest';
import { PayAccountRequest, PayAccountResponse } from '../../../modules/consume/domain/dtos/PayAccountRequest';
import { ValidateAccountPaymentRequest } from '../../../modules/consume/domain/dtos/ValidatePaymentRequest';
import { IConsumeService } from '../../../modules/consume/domain/interfaces/IConsumeService';
import { Api } from '../bases/Api';
import AnticorruptionConsumeApi from './AnticorruptionConsumeApi';
import { CloseAccountRequest } from '../../../modules/consume/domain/dtos/CloseAccountRequest';
import { AccountCancelItemsRequest } from '../../../modules/consume/domain/dtos/AccountCancelItemsRequest';
import { TransferAccountsRequest, TransferAccountsResponse } from '../../../modules/consume/domain/dtos/TransferAccountRequest';

const ConsumeApi = (): IConsumeService => {
  const api = Api();
  const sendOrder = async (request: ConsumeOrderRequest): Promise<any> => {
    const response = await api.post('/CommandEletronic/ConsumeOrder', request);
    return response;
  };

  const getOrderPad = async (localId: string, tag: string): Promise<IGetOrderPadResponse> => {
    const response = await api.post<IGetOrderPadResponse>('/CommandEletronic/Get', { tag, localId });//tag ou numero
    return response.data;
  };

  const getExtract = async (localId: string, orderPadId: string): Promise<GetExtractResponse> => {
    const response = await api.get<GetExtractResponse>(
      `/CommandEletronic/Extract/Account/${orderPadId}/Store/${localId}`,
    );
    return response.data;
  };
  const openAccount = async (request: OpenAccountRequest): Promise<void> => {
    const response = await api.post<OpenAccountRequest>(
      `/CommandEletronic/OpenAccount`, request);
    return
  }

  const getSharedOrderPads = async (localId: string): Promise<GetSharedOrderPadsResponse[]> => {
    const response = await AnticorruptionConsumeApi(api).getSharedOrderOldApi(localId);
    return response;
  };

  const getTables = async (localId: string): Promise<GetTableItemResponse[]> => {
    const response = await AnticorruptionConsumeApi(api).getTablesOrderOldApi(localId);
    return response;
  };

  const validatePaymentAccount = async (request: ValidateAccountPaymentRequest): Promise<void> => {
    const response = await api.post(`/CommandEletronic/ValidatePaymentAccount`, request);
    return
  }
  const payAccount = async (request: PayAccountRequest): Promise<PayAccountResponse> => {
    const response = await api.post<PayAccountRequest, AxiosResponse<PayAccountResponse>>(`/CommandEletronic/PayAccount`, request);
    return response.data
  }

  const closeAccount = async (request: CloseAccountRequest): Promise<void> => {
    const response = await api.post<PayAccountRequest>(`/CommandEletronic/CloseAccount`, request);
  }


  // /api/CommandEletronic/CancelOrderItem
  const cancelAccountItems = async (request: AccountCancelItemsRequest): Promise<void> => {
    const response = await api.post(`/CommandEletronic/CancelOrderItem`, request);
  }


  const transferAccount = async (request: TransferAccountsRequest): Promise<TransferAccountsResponse> => {
    const response = await api.post<TransferAccountsRequest, AxiosResponse<TransferAccountsResponse>>(`/CommandEletronic/TransferAccounts`, request);
    return response.data
  }

  return { sendOrder, getOrderPad, getExtract, getSharedOrderPads, getTables, openAccount, validatePaymentAccount, payAccount, closeAccount, cancelAccountItems, transferAccount };
};
export default ConsumeApi;


//Obter extrato mesa

//abri mesa

//fechar mesa

//Api de abertura de mesa 
// {{base_url}}/api/comandaseletronicasfluxo?localClienteId={{localId}}