import React, { FC, useEffect, useState } from 'react'
const UseDimensions = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerWidth);
    const [fullscreen, setFullscreen] = useState(false);
    useEffect(() => {

        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
            console.log("", navigator.userAgent);
            if (
                (
                    window.innerWidth < 700 ||
                    navigator.userAgent.includes('Android') ||
                    navigator.userAgent.includes('iPhone') ||
                    navigator.userAgent.includes('Mobile')
                ) && window.innerWidth < 1000
            ) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            if (window.innerHeight === window.screen.height) {
                setFullscreen(true);
            } else {
                setFullscreen(false);
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, [])




    return ({ isMobile, width, height, fullscreen })
}

export default UseDimensions