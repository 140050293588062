import { AddCartItemByIdUseCase } from "../../application/useCases/AddCartItemByIdUseCase";
import { AddCartItemByProductUseCase } from "../../application/useCases/AddCartItemByProductUseCase";
import { CalcTotalsCartUseCase } from "../../application/useCases/CalcTotalsCartUseCase";
import { RemoveCartItemByIdUseCase } from "../../application/useCases/RemoveCartItemByIdUseCase";
import { RemoveCartItemByProductIdUseCase } from "../../application/useCases/RemoveCartItemByProductIdUseCase";
import { ICartItem } from "../../domain/models/ICart";
import { IOrderComposition } from "../../domain/models/IOrderComposition";
import { IProductCart } from "../../domain/models/IProductCart";

// const [cartItems, setCartItems] = useState<ICartItem[]>([]);
// const [totals, setTotals] = useState<{
//     totalQuantity: number;
//     totalPrice: number;
// }>({ totalQuantity: 0, totalPrice: 0 });

// const [lastProductAdded, setLastProductAdded] = useState<IProductCart>();

/**
 * Adicionar produto no carrinho caso ja exista adiciona na quantidade
 */

export const addProductCart = (prevCartItems: ICartItem[], product: IProductCart, quantity: number, orderCompositions?: IOrderComposition[], notes?: string) => {
    const cartItems = AddCartItemByProductUseCase(prevCartItems, product, quantity, orderCompositions, notes);
    const lastProductAdded = { ...product, v: new Date().getTime() };
    const totals = CalcTotalsCartUseCase(cartItems);
    return { cartItems, lastProductAdded, totals };
}

export const removeCartItemByProductId = (prevCartItems: ICartItem[], productId: string, quantity: number) => {
    const cartItems = RemoveCartItemByProductIdUseCase(prevCartItems, productId, quantity);
    const totals = CalcTotalsCartUseCase(cartItems);
    return { cartItems, totals }
};

/**
 * Remove quantidade de items do carrinho
 */
export const removeCartItem = (prevCartItem: ICartItem[], cartItemId: string, quantity: number) => {
    const cartItems = RemoveCartItemByIdUseCase(prevCartItem, cartItemId, quantity);
    const totals = CalcTotalsCartUseCase(cartItems);
    return { cartItems, totals }
};
/**
 * Adiciona quantidade de items do carrinho
 */
export const addCartItem = (prevCartItem: ICartItem[], cartItemId: string, quantity: number) => {
    const cartItems = AddCartItemByIdUseCase(prevCartItem, cartItemId, quantity);
    const totals = CalcTotalsCartUseCase(cartItems);
    return { cartItems, totals }
};

/**
 * Limpar carrinho
 */
export const clearCart = () => {
    return { cartItems: [], totals: { totalQuantity: 0, totalPrice: 0 } }
}