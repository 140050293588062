export enum ProductType {
  Produto = 1,
  Recarga = 2,
  Variavel = 3,
  Promocao = 4,

  AtivacaoCashless = 5,
  DesativacaoCashless = 6,

  AtivacaoCashlessPosPago = 7,
  DesativacaoCashlessPosPago = 8,

  AtivacaoCashlessComanda = 9,
  DesativacaoCashlessComanda = 10,
  TaxaServico = 11,
  Kilo = 12,
  Ajuste = 13,
  TaxaEntrega = 14,
  Agendamento = 50,
}
