import { create } from "zustand";
import { StateStorage, createJSONStorage, persist } from "zustand/middleware";
import EncryptedStorage from "../../../services/localStorage/encryptedStorage/EncryptedStorage";
import { v4 } from "uuid";
import { IDevice, IDeviceConfig } from "../interface/Device";
import { OperationMode } from '../presentation/context/OperationMode';
import { GetOperationModeAction, IsSystemTypeAction } from "./actions/SetOperationMode";
import { SystemType } from "../domain/enums/SystemType";
import { IDeviceProfile } from "../interface/DeviceProfile";
import DeviceService from "../../../services/api/device/DeviceService";
import GetDeviceUseCase from "../application/GetDeviceUseCase";
import GetDeviceProfileByDeviceIdUseCase from "../application/GetDeviceConfigUseCase";
import { IHub } from "../../hub/domain/IHub";
import HubService from "../../../services/api/hub/HubService";
import GetHubKeyByLocalIdUseCase from "../../hub/application/useCases/GetHubKeyByLocalIdUseCase";
import { GetPrinterSectorUseCase } from "../../hub/application/useCases/GetPrinterSectorUseCase";
import { IPrinterSector } from "../../hub/domain/IPrinterSector";
import ISimplePos from "../../simplePos/model/ISimplePos";
import GetSimplePosByDeviceUseCase from "../../simplePos/application/useCases/GetSimplePosByDeviceUseCase";
import SimplePosService from "../../../services/api/simplePos/SimplePosService";


export interface IDeviceStore {
    device?: IDevice;
    deviceConfig?: IDeviceProfile;
    isLoadingDevice: boolean;
    setDevice: (device?: IDevice) => void;
    setDeviceConfig: (deviceConfig?: IDeviceProfile) => void;
    operationMode?: OperationMode;
    isSystemType: (systemType: SystemType) => boolean;
    disbledSound: boolean;
    setDisabledSound: (disabled: boolean) => void;
    getDevice: (deviceId: string) => Promise<IDevice>;
    getDeviceConfig: (device: IDevice) => Promise<IDeviceProfile>;
    isLoadingDeviceConfig: boolean;
    clearDevice: () => void;
    hub?: IHub;
    getHub: (localId: string) => Promise<IHub>;
    receiptPrinterSector?: IPrinterSector;
    getReceiptPrinterSector: (sectorId: string) => Promise<IPrinterSector | undefined>;
    simplePos?: ISimplePos[];
    getSimplePos: (localId: string, deviceId: string) => Promise<ISimplePos[]>;
}


const UseDeviceStore = create<IDeviceStore>()(persist(
    (set, get) => ({
        device: undefined,
        deviceConfig: undefined,
        setDevice: (device?: IDevice) => set(store => ({ device })),
        setDeviceConfig: (deviceConfig?: IDeviceProfile) => set(store => ({ deviceConfig, operationMode: GetOperationModeAction(deviceConfig) })),
        operationMode: undefined,
        isSystemType: (systemType) => {
            const { deviceConfig } = get();
            return !!(deviceConfig && IsSystemTypeAction(deviceConfig, systemType))
        },
        disbledSound: true,
        isLoadingDeviceConfig: false,
        isLoadingDevice: false,
        setDisabledSound: (disabled: boolean) => set(store => ({ disbledSound: disabled })),
        getDevice: async (deviceId: string) => {
            try {
                set({ isLoadingDevice: true });
                const deviceService = DeviceService();
                const response = await GetDeviceUseCase(deviceService, deviceId);
                set({ device: response });
                return response;
            } finally {
                set({ isLoadingDevice: false });
            }
        },
        getDeviceConfig: async (device: IDevice) => {
            try {
                set({ isLoadingDeviceConfig: true });
                const deviceApi = DeviceService();
                const profile = await GetDeviceProfileByDeviceIdUseCase(deviceApi, device);
                get().setDeviceConfig(profile);
                return profile;
            } finally {
                set({ isLoadingDeviceConfig: false });
            }
        },
        clearDevice: () => {
            set({ device: undefined, deviceConfig: undefined });
        },
        hubKey: undefined,
        getHub: async (localId: string) => {
            const service = HubService();
            const hub = await GetHubKeyByLocalIdUseCase(service, localId);
            set({ hub });
            return hub;
        },
        receiptPrinterSector: undefined,
        getReceiptPrinterSector: async (sectorId: string) => {
            const service = HubService();
            try {
                const sector = await GetPrinterSectorUseCase(service, sectorId);
                set({ receiptPrinterSector: sector });
                return sector;
            } catch (error) {
                set({ receiptPrinterSector: undefined });
                console.log(error);
                return undefined;
            }
        },
        simplePos: undefined,
        getSimplePos: async (localId: string, deviceId: string) => {
            const service = SimplePosService();
            const simplePos = await GetSimplePosByDeviceUseCase(service, localId, deviceId);
            set({ simplePos });
            return simplePos;
        }
    }),
    {
        name: "device",
        storage: createJSONStorage<StateStorage>(EncryptedStorage),
    }
));

export default UseDeviceStore;