import { SystemType } from "../../domain/enums/SystemType";
import { IDeviceConfig, OrderPadType } from "../../interface/Device";
import { IDeviceProfile } from "../../interface/DeviceProfile";
import { OperationMode } from '../../presentation/context/OperationMode';

export const IsSystemTypeAction = (deviceConfig: IDeviceProfile, _systemType: SystemType) => {
    return !!deviceConfig?.operation.methods?.find(item => item.type === _systemType);
}

export const GetOperationModeAction = (deviceConfig?: IDeviceProfile) => {
    if (deviceConfig) {
        if (IsSystemTypeAction(deviceConfig, SystemType.pospayOrderPad)) {
            return OperationMode.TABLE;
            // } else if (IsSystemTypeAction(deviceConfig, SystemType.pospayOrderPad) && deviceConfig?.operation.isSharedOrderPad === false) {
            //     return OperationMode.ORDER_PAD;
        } else if (IsSystemTypeAction(deviceConfig, SystemType.prepayCashless)) {
            return OperationMode.CASHLESS;
        } else if (IsSystemTypeAction(deviceConfig, SystemType.prepayTicket)) {
            return OperationMode.TICKET;
        } else {
            return OperationMode.TICKET;
        }
    }
}